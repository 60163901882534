.App {
  padding: 0;
  background-color: ghostwhite;
  height: 100vh;
}


.header-background {
  background-color: #34495e; /* Darker shade of blue */
}

/* Text color for the header */
.brand-text {
  color: #ecf0f1; /* Light gray */
  font-size: 2em;
}

.header-text {
  color: #ecf0f1; /* Light gray */
}

.Sidebar {
  width: 120px;
  margin: 5px;
  position: sticky;
  top: 62px;
  align-self: flex-start;
  align-items: end;
}

.Sidebar .nav-item {
  width: 100%;
}

.Sidebar a {
  color: #444;
}

.Sidebar a:hover {
  background-color: #eee;
}

.Sidebar a:visited {
  color: #444;
}

.Sidebar .nav-item .active {
  background-color: #def;
}

div.btnCommand {
  align-self: auto;
  margin-top: 10px;
  text-decoration: none;
}

.noLink {
  text-decoration: none;
  color: black;
}

.InputField {
  margin-top: 15px;
  margin-bottom: 15px;
}

div.incident_header {
  display: grid;
  grid-template-columns: 25% 40% 35%;
  grid-template-rows: auto;
}

div.incident_facts {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: row1-start;
  grid-row-end: 1;
}

div.incident_status {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-left: 3px;
}


.TimelineContainer {
  display: flex;
  justify-content: flex-end; /* Align content to the far right */
  overflow-y: auto; /* Add scrollbars if content overflows vertically */
  max-height: 400px;
  width: 500px;
  height: 1000px;
  position: sticky;
  top: 40%;
  left: 10%;
}

.TimelineColHeader{
  background-color: cornflowerblue;
  border-bottom: 2px solid #f3a011;

}

.centered-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.bodyContainer {
  width: 100%;
}

.responders-box {
  background-color: #f2f2f2; /* Adjust this color to your desired shade */
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 2px; /* Add some spacing above the box */
}
.responders-section {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between label and search box */
}

.search-box {
  flex: 1; /* Take up remaining available space */
}


.incidentcommand_facts {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: row1-start;
  grid-row-end: 1;
  margin-bottom: 20px; /* Add vertical spacing */
  margin-top: 20px;
}

.responders-section {
  padding: 10px;
  margin-top: 20px; /* Increase the top margin */
  margin-bottom: 20px; /* Add vertical spacing */
}



.label-value {
  display: flex;
  align-items: center;
}

.incident-header-label {
  font-weight: bold;
  color: #34495e;
  margin-right: 8px; /* Add some spacing between label and value */
}

.incident-header-value {
  color: #555555;
}
